/* Setting up global variables */
:root{
    --slide:0;
}
body{
    background-color: #f0f0f0 !important;
}
.navigation-container{
    width: 100%;
    height: auto;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.home-button{
    font-size: 2.5rem;
    padding: 1rem;
    color: #727577;
    transition: 0.3s;
}
.home-button:hover{
    color: #444749;
    
}
.project-head-container{
    max-width: 1024px;
    margin: auto;
    padding: 1rem;
    background-color: #f5f5f5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}
.project-image-container{
    display: flex;
    flex-direction: column;
}
.project-image{
    max-width: 10rem;
    max-height: 10rem;
    padding: 1rem;
}

.project-title{
    font-weight: bold;
}

.project-info{
   width: 70%;
   margin: auto;
}

.project-button-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.project-button{
    border: 2px solid #343a40;
    border-radius: 20px;
    padding-inline: 1rem;
    margin: 0.3rem;
    font-weight: bold;
    /* Colors */
    color: #343a40;
    background-color: #f5f5f5;
    /* Animations */
    transition: all 0.4s;
}

.project-button:hover{
background-color: #343a40;
color: #f5f5f5;
}

.live-button{
    color: #1e87ff;
    border: 2px solid #1e87ff;
}
.live-button:hover{
    color: #e0e0e0;
    background-color: #1e87ff;
}

.like-button{
    color: #d12626;
    border: 2px solid #d12626;
    padding-inline: 0.5rem;
    margin: 1rem;
    margin-top: 0rem;
}
.like-button:hover{
    color: #e0e0e0;
    background-color: #d12626;
}

.like-button-liked{
    border: 2px solid #d12626;
    padding-inline: 0.5rem;
    margin: 1rem;
    margin-top: 0rem;
    color: #e0e0e0;
    background-color: #d12626;
}

.like-button-liked:hover{
    color: #e0e0e0;
    background-color: #d12626;
}

.like-icon{
    transition: transform 0.4s;
}
.project-button:hover > .like-icon{
transform: translateX(-30%);
transition: 0.2s;
}

.project-body-container{
    max-width: 1024px;
    margin: auto;
    padding: 1rem;
    padding-bottom: 0rem;
    background-color: #f0f0f0;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.project-section-title{
    align-self: left;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
}

.project-section-title h2{
    font-weight: bold;
}

.project-slide{
    min-height: 32rem;
    overflow:hidden;
    position: relative;
    display: flex;
    border-radius: 15px;
    box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.09);
}

.project-slide-image{
    position: relative;
    width: 100%;
    object-fit: cover;
    transform: translateX(calc(var(--slide) * 100% * -1));
    transition: transform 0.5s ease-in-out;
}

.project-slide-button-left{
    position: absolute;
    top: 45%;
    left: 1rem;
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    opacity: 0.3;
    color: black;
}
.project-slide-button-right{
    position: absolute;
    top: 45%;
    right: 1rem;
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    opacity: 0.3;
    color: black;
}
.slide-points{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem;
}

.slide-points-button{
    background-color: transparent;
    border:none;
    margin-inline: 0.1rem;
    opacity: 0.2;
    color: black;
    transition: 0.5s;
}

.active-slide-button{
    opacity: 0.6 !important;
}

.hover-opacity-1:hover{
    opacity: 0.7 !important;
    transition: 0.4s;
    transform: scale(120%);
}
.general-container{
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    margin: auto;
    flex-wrap: wrap;
    background-color: #f5f5f5;
}
.tech-container{
    width: 50%;
    padding: 1rem;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}
.role-container{
    width: 50%;
    min-width: 20rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}
.tech-used-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
}
.tech-used-container img{
    width: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 50%;
}
.tech-used-item{
    margin-inline: 1rem;
    min-width: 8rem;
}
.features-container{
    max-width: 1024px;
    margin: auto;
    background-color: #f5f5f5;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
}
.difficulty-solution-container{
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    margin: auto;
    flex-wrap: wrap;
    background-color: #f5f5f5;
}

.feature-section{
    max-width: 30rem;
    padding-left: 1rem;
}


