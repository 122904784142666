/* Setting up global variables */
:root{
    --primary-color: #343a40;
    --secondary-color: #828486;
    --background-color: #f5f5f5;
    --experiences-color: #18191b;
    --text-color:#343a40;
}

/* Making the text color in the body dependent on this css file rather than the default one set by bootstrap */
body{
    color:var(--primary-color) !important;
}

.btn.focus, .btn:focus{
    box-shadow: 0 0 0 0.2rem rgb(80 80 80 / 25%) !important;
}

.header-container{
display: flex;
justify-content: space-between;
background-color: var(--background-color);
padding:1rem;
}

.theme-switch{
    font-size: 2rem;
    border: none;
    /* Colors */
    background-color: transparent;
    color: var(--primary-color);
    /* Animations */
    transition: all 0.4s;
}
.theme-switch:hover{
    transform: scale(0.8);
    color: var(--secondary-color);
}

.language-switch{
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    padding-inline: 1rem;
    font-weight: bold;
    /* Colors */
    color: var(--primary-color);
    background-color: var(--background-color);
    /* Animations */
    transition: all 0.4s;
}
.language-switch:hover{
background-color: var(--primary-color);
color: var(--background-color);
}

.main-page{
    padding-inline: 1rem;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.aboutMe-container{
    max-width: 30rem;
    margin-inline: auto;
    text-align: center;
}

.reveal-animation{
    opacity: 0;
    animation:  revealText 1s forwards ;
}
.reveal-animation.delay-1s{
    opacity: 0;
    animation:  revealText 1s 1s forwards ;
}

.reveal-animation.delay-2s{
    opacity: 0;
    animation:  fadeIn 1s 2s forwards ;
}
.reveal-animation.delay-3s{
    opacity: 0;
    animation:  fadeIn 1s 3s forwards ;
}



@keyframes revealText {
    0%{
        clip-path: polygon(8% 100%, 84% 100%, 100% 100%, 0% 100%);
    }
    100%{
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.social-container{
    display: flex;
    justify-content: center;
}
.social-links svg{
    color: var(--primary-color);
    font-size: 3.5rem;
    /* Animations */
    transition: all 0.4s;
}
.social-links svg:hover{
    transform: scale(150%);
    opacity: 0.8;
}

.scroll_down {
    opacity: 0;
    font-size:5rem;
    cursor: pointer;
    color: var(--secondary-color) !important;
    z-index: 1;
    animation: fadeIn 1s 3.5s ease-in forwards, wiggle 1s ease-in infinite alternate;
    transition: all 0.3s;
}
@keyframes wiggle {
    0%{
        transform: translateY(0%);
    }
    100%{
        transform: translateY(20%);
    }
}
.scroll_down :hover{
    color: var(--primary-color);
    transition: all 0.3s;
}


.circuit-image{
    opacity: 0.05;
    animation: rotate 30s linear infinite;
    -webkit-user-drag: none;
}
@keyframes rotate {
    100%{
        transform: rotate(360deg)
    }
}

.experiences{
    background-color: var(--experiences-color) ;
    padding: 1rem;
    box-shadow: 0 -10px 25px rgb(0 0 0 / 24%);
}

.experiences .head{
display: flex;
justify-content:center;
}
.experiences .head .title {
    color: #f5f5f5;
}


.experiences .body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 1rem;
}

.experiences .body .link{
    text-decoration: none !important;
    color:var(--primary-color);
}

.experiences .body .item{
    background-color: var(--background-color);
    max-width: 60rem;
    min-height: 28vh;
    border-radius: 5px;
    margin:1rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 24%);
    transition: all 0.3s ease-out ;
    /* Position */
    display: flex;
    flex-direction: column;
}



.item .logo{
    max-height: 25vh;
    float: left;
    padding: 1rem;
}

.project_description{
    padding:1rem;
    font-size: large;
}

.tools{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline: 1rem;
}

.tools .tool-logo{
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%);
    margin: 0.2rem;
}

.learnMore-container{
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}
.learnMore-button{
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    font-weight: bold;
    /* Colors */
    color: var(--primary-color);
    background-color: var(--background-color);
    /* Animations */
    transition: all 0.4s;
}
.learnMore-button:hover{
background-color: var(--primary-color);
color: var(--background-color);
}

.quote{
    display: block;
    text-align: center;
    color: #f5f5f5;
    padding: 3rem;
    
}










